/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import dashAuth from "@/store/auth/dashAuth";
import i18n from "./i18n/i18n";
import 'firebase/auth'

Vue.use(Router);


const router = new Router({
    i18n,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [

        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/',
                    redirect: '/pages/login',
                },

                {
                    path: "/Transfer/:selected/:index",
                    name: 'transfer',
                    component: () => import('./views/Transfer.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },


                {
                    path: '/order/:id',
                    name: 'order',
                    component: () => import('./views/Order.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/admin/:id',
                    name: 'admin',
                    component: () => import('./views/Admin.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },

                {
                    path: '/apps/chat',
                    name: 'chat',
                    component: () => import('./views/apps/chat/Chat.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },

                {
                    path: '/delivery',
                    name: 'delivery',
                    component: () => import('./views/Delivery.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },





                // =============================================================================
                // FORMS
                // =============================================================================
                // =============================================================================




                // =============================================================================
                // EXTENSIONS
                // =============================================================================
                {
                    path: '/extensions/select',
                    name: 'extraComponentSelect',
                    component: () => import('@/views/components/extra-components/select/Select.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extra Components'},
                            {title: 'Select', active: true},
                        ],
                        pageTitle: 'Select',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/quill-editor',
                    name: 'extraComponentQuillEditor',
                    component: () => import('@/views/components/extra-components/quill-editor/QuillEditor.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extra Components'},
                            {title: 'Quill Editor', active: true},
                        ],
                        pageTitle: 'Quill Editor',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/drag-and-drop',
                    name: 'extraComponentDragAndDrop',
                    component: () => import('@/views/components/extra-components/drag-and-drop/DragAndDrop.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extra Components'},
                            {title: 'Drag & Drop', active: true},
                        ],
                        pageTitle: 'Drag & Drop',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/datepicker',
                    name: 'extraComponentDatepicker',
                    component: () => import('@/views/components/extra-components/datepicker/Datepicker.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extra Components'},
                            {title: 'Datepicker', active: true},
                        ],
                        pageTitle: 'Datepicker',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/access-control-editor',
                    name: 'extraComponentAccessControlEditor',
                    component: () => import('@/views/components/extra-components/access-control/AccessControlEditor.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extensions'},
                            {title: 'Access Control'},
                            {title: 'Editor View', active: true},
                        ],
                        pageTitle: 'Editor View',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/access-control-admin',
                    name: 'extraComponentAccessControlOnlyAdmin',
                    component: () => import('@/views/components/extra-components/access-control/AccessControlAdmin.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extensions'},
                            {title: 'Access Control'},
                            {title: 'Admin View', active: true},
                        ],
                        pageTitle: 'Admin View',
                        rule: 'admin'
                    },
                },
                {
                    path: '/extensions/i18n',
                    name: 'extraComponentI18n',
                    component: () => import('@/views/components/extra-components/I18n.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extensions'},
                            {title: 'I18n', active: true},
                        ],
                        pageTitle: 'I18n',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/carousel',
                    name: 'extraComponentCarousel',
                    component: () => import('@/views/components/extra-components/carousel/Carousel.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extensions'},
                            {title: 'Carousel', active: true},
                        ],
                        pageTitle: 'Carousel',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/clipboard',
                    name: 'extraComponentClipboard',
                    component: () => import('@/views/components/extra-components/clipboard/Clipboard.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extensions'},
                            {title: 'Clipboard', active: true},
                        ],
                        pageTitle: 'Clipboard',
                        rule: 'editor'
                    },
                },
                {
                    path: '/extensions/context-menu',
                    name: 'extraComponentContextMenu',
                    component: () => import('@/views/components/extra-components/context-menu/ContextMenu.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Extensions'},
                            {title: 'Context Menu', active: true},
                        ],
                        pageTitle: 'Context Menu',
                        rule: 'editor'
                    },
                },
            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================

                {
                    path: '/pages/login',
                    name: 'pageLogin',
                    component: () => import('@/views/pages/Login.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/login2',
                    name: 'pageLogin2',
                    component: () => import('@/views/pages/Login2.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/register',
                    name: 'pageRegister',
                    component: () => import('@/views/pages/Register.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/forgot-password',
                    name: 'pageForgotPassword',
                    component: () => import('@/views/pages/ForgotPassword.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/reset-password',
                    name: 'pageResetPassword',
                    component: () => import('@/views/pages/ResetPassword.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/lock-screen',
                    name: 'pageLockScreen',
                    component: () => import('@/views/pages/LockScreen.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/comingsoon',
                    name: 'pageComingSoon',
                    component: () => import('@/views/pages/ComingSoon.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                 {
                    path: '/pages/report',
                    name: 'report',
                    component: () => import('@/views/pages/Report.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/not-authorized',
                    name: 'pageNotAuthorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
                {
                    path: '/pages/maintenance',
                    name: 'pageMaintenance',
                    component: () => import('@/views/pages/Maintenance.vue'),
                    meta: {
                        rule: 'editor'
                    }
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '/',
            redirect: '/pages/login'
        }
    ],
})



export default router
