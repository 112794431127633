/*=========================================================================================
  File Name: moduleAuth.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

class DashAuth {
    constructor() {
        this.loggedIn = true;
        const token = localStorage.getItem('jwt');
        if (token === null) {
            this.loggedIn = false;
        }
    }

    setLogged(state) {
        this.loggedIn = state;
    }

    logout() {
        localStorage.removeItem('jwt');
        localStorage.removeItem('passphrase');
        localStorage.removeItem('passphrase_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('merchant_id');
        localStorage.removeItem('permissions');
        localStorage.removeItem('store');
        localStorage.removeItem('store_id');
        localStorage.removeItem('filters');
        localStorage.removeItem('filtersDate');
        localStorage.removeItem('selectedFilterStore');
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('selectedStore');
    }
}


const dashAuth = new DashAuth();
export default dashAuth;
