import config from "../../config";

export default {
    install(Vue) {
        Vue.prototype.$storeMap = new class {

            async createWall(wallData = {}) {
                const wallItem = {
                    name: wallData.name,
                    floor_id: Number(wallData.floor_id),
                    points: []
                };
                const {data} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/map/area`, {
                        name: wallData.name,
                        floor_id: Number(wallData.floor_id),
                    }, this.config
                );
                const wallId = data.data.id;
                wallItem.area_id = wallId;

                for (let i = 0; i < wallData.points.length; i++) {
                    const itemP = wallData.points[i];

                    const {data: pointData} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/map/area/coordinate`, {
                            area_id: wallItem.area_id,
                            x: itemP[0],
                            y: itemP[1]
                        }, this.config
                    );

                    const coordinateId = pointData.id;
                    wallItem.points.push([
                        ...itemP,
                        coordinateId,
                    ])

                }
                return wallItem;
            }

            async updateWall(wallData = {}) {
                const wallItem = {
                    name: wallData.name,
                    area_id: wallData.area_id,
                    floor_id: Number(wallData.floor_id),
                    points: []
                };
                await Vue.prototype.$http.put(`${config.API_BASE}/api/v1/map/area`, {
                        name: wallData.name,
                        id: wallItem.area_id,
                        floor_id: Number(wallData.floor_id),
                    },
                    this.config
                );

                for (let i = 0; i < wallData.points.length; i++) {
                    const itemP = wallData.points[i];
                    if (itemP[2]) {
                        // update point
                        await Vue.prototype.$http.put(`${config.API_BASE}/api/v1/map/area/coordinate`, {
                            x: itemP[0],
                            y: itemP[1],
                            id: itemP[2],
                        }, this.config);
                    } else {
                        // create point
                        const {data: pointData} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/map/area/coordinate`, {
                                area_id: wallItem.area_id,
                                x: itemP[0],
                                y: itemP[1]
                            }, this.config
                        );
                        itemP[2] = pointData.id;
                    }
                }


                return wallItem;
            }

            async createZone(zoneData = {}) {
                const zoneItem = {
                    name: zoneData.name,
                    selectedZoneType: zoneData.selectedZoneType,
                    points: []
                };
                const {data} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/zone`, {
                        name: zoneData.name,
                        type_id: zoneData.selectedZoneType,
                        store_id: Number(zoneData.store_id),
                    }, this.config
                );


                const zoneId = data.id;
                zoneItem.id = zoneId;

                await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/zone/floor_assignment`, {

                        floor_id: Number(zoneData.floor_id),
                        zone_id: zoneId,

                    }, this.config
                );


                for (let i = 0; i < zoneData.points.length; i++) {
                    const itemP = zoneData.points[i];
                    const {data: pointData} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/zone/coordinate`, {
                            zone_id: zoneId,
                            x: itemP[0],
                            y: itemP[1]
                        }, this.config
                    );
                    const coordinateId = pointData.id;
                    zoneItem.points.push([
                        ...itemP,
                        coordinateId,
                    ])
                }
                console.log("COORDS CREATED", zoneItem);
                return zoneItem;
            }

            async updateZone(zoneData = {}) {
                await Vue.prototype.$http.put(`${config.API_BASE}/api/v1/zone`, {
                        name: zoneData.name,
                        id: zoneData.id,
                        zone_id: zoneData.zoneId,
                        type_id: zoneData.selectedZoneType,
                        store_id: Number(zoneData.store_id),
                    }, this.config
                );

                for (let i = 0; i < zoneData.points.length; i++) {
                    const itemP = zoneData.points[i];
                    if (itemP[2]) {
                        await Vue.prototype.$http.put(`${config.API_BASE}/api/v1/zone/coordinate`, {
                            x: itemP[0],
                            y: itemP[1],
                            id: itemP[2],
                            zone_id: zoneData.id,
                        }, this.config);
                    } else {
                        const {data: pointData} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/zone/coordinate`, {
                                zone_id: zoneData.id,
                                x: itemP[0],
                                y: itemP[1]
                            }, this.config
                        );
                        itemP[2] = pointData.id;
                    }
                }
            }

            async createScanner(scannerData = {}) {
                const scannerItem = {
                    name: scannerData.name,
                    floor_id: Number(scannerData.floor_id),
                    points: scannerData.points,
                    source_user_id: Number(scannerData.source_user_id),
                    merchant_id: Number(scannerData.merchant_id),
                    store_id: Number(scannerData.store_id)
                };
                const {data} = await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/device`, {
                        device_type_id: 1,
                        identifier: scannerData.name,
                    }, this.config
                );
                const scannerId = data.id;
                scannerItem.device_id = scannerId;

                await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/device/${scannerId}/merchant_assignment`, {
                        source_user_id: Number(scannerItem.source_user_id),
                        merchant_id: Number(scannerItem.merchant_id)
                    }, this.config
                );

                await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/device/${scannerId}/store_assignment`, {
                        device_id: scannerId,
                        source_user_id: Number(scannerItem.source_user_id),
                        store_id: Number(scannerItem.store_id)
                    }, this.config
                );


                Vue.prototype.$http.post(`${config.API_BASE}/api/v1/device/coordinate`, {
                        device_id: scannerId,
                        floor_id: Number(scannerData.floor_id),
                        x: scannerItem.points[0][0],
                        y: scannerItem.points[0][1]
                    }, this.config
                );


                return scannerItem;
            }

            async updateScanner(scannerData = {}) {
                const scannerItem = {
                    name: scannerData.name,
                    floor_id: Number(scannerData.floor_id),
                    points: scannerData.points,
                    source_user_id: Number(scannerData.source_user_id),
                    merchant_id: Number(scannerData.merchant_id),
                    store_id: Number(scannerData.store_id),
                    device_id: Number(scannerData.device_id),
                    id: Number(scannerData.id)

                };

               /* await Vue.prototype.$http.put(`${config.API_BASE}/api/v1/device`, {
                        device_type_id: 1,
                        identifier: scannerItem.name,
                        id: Number(scannerData.id),
                    }, this.config
                );*/

                await Vue.prototype.$http.post(`${config.API_BASE}/api/v1/device/coordinate`, {
                        device_id: scannerItem.device_id,
                        floor_id: Number(scannerData.floor_id),
                        x: scannerItem.points[0][0],
                        y: scannerItem.points[0][1]
                    }, this.config
                );


                return scannerItem;

            }

        }
    }
};
