/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import "vue-material-design-icons/styles.css"
import 'material-icons/iconfont/material-icons.css' //Material Icons
import vSelect from 'vue-select'
import VueHtml2pdf from 'vue-html2pdf'
import VueAnalytics from 'vue-analytics'
import excel from 'vue-excel-export'
import animate from 'animate.css'
import qs from 'qs'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import UUID from "vue-uuid";
import VueFusionChartsComponent from 'vue-fusioncharts/component';
import VueCurrencyFilter from 'vue-currency-filter'
import 'vue-fabric/dist/vue-fabric.min.css';
import { Fabric } from 'vue-fabric';
import Vuikit from 'vuikit'
import VuikitIcons from '@vuikit/icons'
import '@vuikit/theme'
const moment = require('moment');
import StoragePlugin from 'vue-web-storage';

Vue.use(StoragePlugin,{
    prefix: 'bbd_',
    drivers: ['session', 'local'], // default 'local'
});

// vue-moment
Vue.use(require('vue-moment'), {
    moment
});



Vue.use(Fabric);
Vue.use(VueHtml2pdf);
Vue.config.productionTip = false
Vue.use(VueCurrencyFilter)


Vue.use(Vuikit)
Vue.use(VuikitIcons)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(excel)
Vue.use(qs);
Vue.component('v-select', vSelect);
Vue.use(UUID);

Vue.use(Vuetify);
// Vuesax Component Framework
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css'; // Vuesax
const datepickerOptions = {
    dateLabelFormat: 'dddd, MMMM D, YYYY',
};

/*Vue.use(new VueSocketIO({
    debug: true,
    connection: 'http://metinseylan.com:1992',
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    options: { path: "/my-app/" } //Optional options
}));*/

Vue.use(Vuesax);

Vue.use(animate);
Vue.use(AirbnbStyleDatepicker, datepickerOptions)
// axios

import config from "../config";
import axios from 'axios'
Vue.prototype.$http = axios;
/*axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['access-control-allow-origin'] = '*';*/








// Theme Configurations
import '../themeConfig.js'
// Algolia - Instant Search
import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);
// Firebase
import '@/firebase/firebaseConfig'
import '@/firebase/firebaseConfig'
import dashAuth from "@/store/auth/dashAuth";

const Auth = {
    logout() {
        localStorage.removeItem('jwt');
        localStorage.removeItem('passphrase');
        localStorage.removeItem('passphrase_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_name');
        localStorage.removeItem('merchant_id');
        localStorage.removeItem('permissions');
        localStorage.removeItem('store');
        localStorage.removeItem('store_id');
        localStorage.removeItem('filters');
        localStorage.removeItem('filtersDate');
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('selectedStore');
        localStorage.removeItem('selectedCampaign');
    },
    install(Vue) {
        Vue.mixin({});
        Vue.prototype.$auth = function () {
            return dashAuth;
        };
    },
};
Vue.use(Auth);
// ACL
import acl from './acl/acl'
// Globally Registered Components
import './globalComponents.js'
// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';

// i18n
import i18n from './i18n/i18n'

// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

// Vuesax Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);

// import FusionCharts modules and resolve dependency
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
const vueFusionCharts = VueFusionChartsComponent(FusionCharts, Charts);
Vue.component('fusioncharts', vueFusionCharts);


// Tour
import VueTour from 'vue-tour'

Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);


import StoreMapService from './plugins/StoreMapService';
Vue.use(StoreMapService);



// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
        key: 'YOUR_KEY',
        libraries: 'places', // This is required if you use the Auto complete plug-in
    },
});


let locale = localStorage.getItem("locale");
if (locale) {
    moment.locale(locale)
}

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')
Vue.config.productionTip = false;


Vue.use(VueAnalytics, {
    id: 'UA-151879730-1',
    router,
});

new Vue({
    router,
    store,
    i18n,
    acl,
    render: h => h(App)
}).$mount('#app')
