/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
	primary: '#c10028',
	success: '#28C76F',
	danger: '#EA5455',
	warning: '#FF9F43',
	dark: '#1E1E1E',
	lightBlue: '#77b4db',
	darkBlue: '#2834db',
	yellow: '#fdc71c',
	pink: '#f54de9',
	purple: '#8e2af5',
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, { theme:{ colors } });


// CONFIGS
const themeConfig = {
	theme: 'semi-dark',						// options[String]: 'dark'(default), 'light', 'semi-dark'
	sidebarCollapsed: false,			// options[Boolean]: true, false(default)
	navbarColor: "#fff",				// options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
	navbarType: "floating",				// options[String]: floating(default) / static / sticky / hidden
	footerType: "static",				// options[String]: static(default) / sticky / hidden
	routerTransition: 'zoom-bottom',		// options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
	disableCustomizer: false,			// options[Boolean]: true, false(default)
	hideScrollToTop: false,				// options[Boolean]: true, false(default)
	disableThemeTour: true,					// options[Boolean]: true, false(default)
  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
}

export default themeConfig
