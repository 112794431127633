<template>
  <div id="app">
    <head>
      <link rel="stylesheet" href="https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css">
    </head>
    <router-view></router-view>
  </div>
</template>


<script>
import themeConfig from '@/../themeConfig.js'

export default {
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    }
  },
  async created() {
    try {
      await this.$auth.renewTokens();
    } catch (e) {
      //console.log(e);
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
      }
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
  },
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
body {
  overflow-x: hidden!important;
  .vs-table::-webkit-scrollbar {
    display: none;
  }
  #app {
    background: #f1f1f1;
    color: #293241;
    font-family: 'Open Sans', sans-serif;

  }
  .apexcharts-legend.position-bottom.apexcharts-align-left, .apexcharts-legend.position-top.apexcharts-align-left, .apexcharts-legend.position-right, .apexcharts-legend.position-left{
    justify-content: center!important;
  }
  .asd__day--in-range{
    color:white!important;
    background: #3d5a80!important;
    border: none!important;
  }
  .asd__day--hovered{
    color:white!important;
    background: #3d5a8044!important;
    border: none!important;
  }
  .datepicker-trigger .asd__day-button:hover{
    background: #3d5a8066!important;
  }
  .asd__selected-date-one{
    border-radius: 20px 0 0 20px!important;
  }.asd__selected-date-two{
       border-radius: 0 20px 20px 0!important;
  }
  .apexcharts-legend-marker{
    border-radius: 6px!important;
    top: 1px!important;
    width: 15px!important;
    height: 15px!important;
  }
  .apexcharts-legend-text{
    font-size: 14px!important;
    margin-top: 3px;
  }
  .apexcharts-legend.position-bottom, .apexcharts-legend.position-top{
    position: absolute!important;
    bottom: 0!important;

  }
  .lds-dual-ring {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: auto;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 44px;
    height: 44px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #3d5a80;
    border-color: #3d5a80 transparent #3d5a80 transparent;
    animation: lds-dual-ring 1.2s linear infinite !important;
  }
  .vs-pagination-primary .effect {
    background: #3d5a80 !important;
  }

  .vs-checkbox-primary input:checked + .vs-checkbox {
    border: 2px solid #3d5a80 !important;
    border-radius: 6px;
  }

  .checkbox_x, .vs-checkbox {
    border-radius: 2px !important;
    border-color: #f1f1f1 !important;
  }

  .ClearBtnS {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    height: 35px !important;
    line-height: 25px;
    color: #39404e !important;
    width: 165px;
    background: #f1f1f1;
    margin-right: 10px;


  }

  .ApplyBtnS {

    padding: 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    height: 35px !important;
    width: 120px;
    line-height: 25px;
    background: #3d5a80;
    margin-right: 5px;
    color: white;
  }
  .vs-table--tbody .vs-table--thead th{
    color: black!important
  }

  .con-vs-checkbox {
    margin-left: 0 !important;
  }
}

.asd__action-buttons {
  position: absolute;
  right: -140px;
  margin-top: 23px;
  background: #3D5A80;
  border-radius: 5px;
  color: white !important;
  width: 70px;
  min-height: 40px !important;
}
#campaignDate .asd__action-buttons[class]{
  position: absolute;
  right: -10px!important;
  margin-top: 13px;
  background: #3d5a80;
  border-radius: 5px;
  color: white !important;
  width: 70px;
  min-height: 43px !important;
}
#marginClassS .asd__action-buttons[class] {
  position: absolute;
  right: -140px;
  margin-top: 50px;
  background: #3d5a80;
  border-radius: 5px;
  color: white !important;
  width: 70px;
  min-height: 40px !important;
}
.popover .arrow{
  display: none!important;
}
#marginClassAverage .asd__action-buttons[class] {
  position: absolute;
  right: -140px;
  margin-top: 23px;
  background: #3d5a80;
  border-radius: 5px;
  color: white !important;
  width: 70px;
  min-height: 40px !important;
}
.asd__datepicker-header .asd__change-month-button--previous ,.asd__datepicker-header .asd__change-month-button--next {
  line-height: 18px !important;
}
.asd__action-buttons button {
  color: white !important;
}
.asd__days-legend{
  top: 48px!important;
}

.asd__action-buttons button:first-child {
  display: none !important;
}

.asd__change-month-button {
  background: transparent !important;
}

.asd__keyboard-shortcuts-trigger {
  display: none;
}

.breadcrumb {
  position: relative !important;
  z-index: 999;
}

hr {
  margin-top: 13px !important;
}

.vs-breadcrumb.vs-align-left {
  position: relative !important;
  z-index: 999 !important;
  width: 80% !important;
}

.vx-card {
  margin-top: 30px;
  padding: 15px;

  .vs-con-table .vs-con-tbody {
    border: none;

    .vs-table--tbody-table .vs-table--thead th {
      font-weight: bold !important;
      color: #1c1b1b;
      font-size: 12px;

    }

    .td-check {
      display: none !important;
    }
  }
}

.con-vs-loading {
  background: none !important;
}

.main-menu-sidebar .header-sidebar .feather-icon svg {
  color: #5b5b5b !important;


}

.vs-breadcrumb--ol {
  padding-left: 0 !important;

  a {
    color: #858585 !important;
  }
}

.vs-breadcrumb--ol li.vs-active {
  line-height: 20px;
  cursor: default;
  font-weight: 600;
  font-size: 16px;
}

.ph-picture {
  background: #ffffff !important;
  color: white !important;
  padding: 0 !important;
  margin-bottom: 25px !important;
}

.ph-col-4 {
  padding: 0 10px !important;
}

.ph-item {
  margin-top: 30px;
  margin-bottom: 0 !important;
}

.placeHolder {
  background: #f6f6f6 !important;
}

.ph-item:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation .8s linear infinite;
  animation: phAnimation .8s linear infinite;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 96%, 0.35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50% !important;
}

.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none !important;
}

.sticky-table-head thead tr th {
  width: auto;
}

.vs-pagination--li, .vs-pagination--buttons {
  background: white !important;
  border-radius: 20px !important;
}

.vs-pagination--ul {
  background: none !important;

}

.feather-menu {
  display: none;
}

.vs-checkbox-primary .vs-checkbox--check {
  background: none !important;

}

.vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  color: #3d5a80 !important;
}

.vs-checkbox--check {
  background: transparent !important;
}

.vue-back-to-top {
  display: none;

}

.vs-radio-primary .vs-radio--circle {
  border-radius: 5px;
  background: #3d5a80 !important;
  box-shadow: none !important;
}

.vs-radio--borde {
  border-radius: 2px !important;
  border-color: #f1f1f1 !important;
}

.td-check {
  display: none !important;

}
@media screen and (max-width: 500px) {

body {
  overflow-x: hidden!important;
}
}

</style>
