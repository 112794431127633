/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
    state: {
        auth: false,
        isAdmin: '',
        newPassword: '',
        conNewPassword: '',
        loginData: {
            password: '',
            passphrase: '',
            user_id: '',
            jwt: '',
            merchant_id: '',
        }
    },
    mutations: {
        setAuth(state, payload) {
            state.auth = payload;
            // TODO: Write remote upate
        },
        isAdmin(state, payload) {
            state.isAdmin = payload;
        },
        loginData(state, payload) {
            state.loginData = payload;
        },
    },
    getters: {
        app: state => {
            return state;
        },
    },
    actions: {
        setAuth(c, payload) {
            c.commit('setAuth', payload);
        },
        isAdmin(c, payload) {
            c.commit('isAdmin', payload);
        },
        loginData(c, payload) {
            c.commit('loginData', payload);
        },
    },
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || 'light',
    navbarSearchAndPinList: navbarSearchAndPinList,
    AppActiveUser: {
        id: 41,
        name: 'Gözde Başkuş',
        about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
        img: 'avatar-s-2.png',
        status: 'online',
    },

    themePrimaryColor: colors.primary,

    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    userRole: null,
};

export default state
