<!-- =========================================================================================
    File Name: VxBreadcrumb.vue
    Description: Breadcrumb component
    Component Name: VxBreadcrumb
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="vx-breadcrumb">
        <ul class="flex flex-wrap items-center">

            <li v-for="(link, index) in $route.meta.breadcrumb.slice(1,-1)" :key="index"
                class="inline-flex items-center">
                <router-link :to="applyLinkParams(link.url)" v-if="link.url">{{ $t(link.title) }}</router-link>
                <span class="text-primary cursor-default" v-else>{{ link.title }}</span>
                <span class="breadcrumb-separator mx-2 flex items-start"><feather-icon icon="ChevronsRightIcon" svgClasses="w-4 h-4"/></span>
            </li>
            <li class="inline-flex">
                <span style="font-weight: bold" v-if="$route.meta.breadcrumb.slice(-1)[0].active" class="cursor-default">{{ $t($route.meta.breadcrumb.slice(-1)[0].title) }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'vx-breadcrumb',
        methods: {
            applyLinkParams(link) {
                let replacedLink = link;
                Object.keys(this.$route.params)
                    .map((item) => {
                        if (replacedLink.indexOf(`:${item}`) !== -1) {
                            replacedLink = replacedLink.replace(`:${item}`, this.$route.params[item]);
                        }
                    });
                return replacedLink
            }
        }
    }
</script>
<style lang="scss">

    .vx-breadcrumb{
        position: relative !important;
        z-index: 999 !important;
    }


</style>
