// en, de, fr, pt
export default {
    en: {
        inboxes:'Inboxes',
        createUser:'Create User',
        newTransfer:'New Transfer',
        selectCari:'Choose Company',
        user:'Username',
        note:'Notes',
        add:'Add',
        date:'Date',
        logout:'Logout',
        onGoingOrder:'On Going Order',
        completedOrder:'Orders to be loaded',
        activeOrder :'Active Order',
        ordersInWarehouse:'Orders in Warehouse',
        sentBranch:'Sent Branch',



        boxCount:'boxCount',
        approve:'Approve',
        pleaseSelectLoaderCompany:'Please Select Loader Company',
        boxID:'Box ID',
        loaderCompany:'Loader Company',
        userName:'User Name',
        password:'Password',
        loginDes:'Welcome again',
        login:'Login',
        welcome:'Welcome',
        orderID:'Order ID',
        orderDate:'Order Date',
        productID:'Product ID',
        barcode:'Barcode',
        productName:'Product Name',
        quantity:'Quantity',
        transport:'Transport',
        remaining:'Remaining',
        branch:'Branch',
        notes:'Notes',
        shipVia:'Ship Via',
        shippedDate:'Shipped Date',
        submit:'Submit',
        sea:'Sea',
        air:'Air',
        pleaseSelectTransportType:'Please Select Transport Type',
        pleaseSelectTransportCity:'Please Select Transport Branch',
        transportCount:'Transport Count',
        transfer:'Transfer',
        pleaseSelectOne:'Please Select One',
        createNewUser:'Create New User',
        companyName:'Company Name',

    }
    ,
    tr: {
        companyName:'Şirket Adı',
        createNewUser:'Yeni Kullanıcı Yarat',
        inboxes:'Gelen Kutu',
        boxCount:'Kutu Miktarı',
        approve:'Onayla',
        createUser:'Yeni Kullanıcı',
        newTransfer:'Yeni Transfer',
        selectCari:'Cari Seç',
        user:'Kullanıcı Adı',
        note:'Notlar',
        notes:'Notlar',
        add:'Ekle',
        date:'Tarih',
        logout:'Çıkış',
        onGoingOrder:'Devam Eden Siparişler',
        completedOrder:'Yüklemeye Hazır Siparişler',
        activeOrder:'Aktif Siparişler',
        ordersInWarehouse:'Depodaki Siparişler',
        sentBranch:'Gönderilen Şube',


        pleaseSelectLoaderCompany:'Lütfen Yükleyici Firma Seçin',
        boxID:'Kutu ID',
        loaderCompany:'Yükleyici Firma',
        userName:'Kullanıcı Adı',
        password:'Parola',
        loginDes:'Yeniden Hoş Geldin.',
        login:'Giriş',
        welcome:'Hoş Geldin',
        orderID:'Sipariş Num.',
        orderDate:'Sipariş Tarihi',
        transport:'Transfer',
        productID:'Ürün ID',
        barcode:'Barkod',
        productName:'Ürün İsmi',
        quantity:'Miktar',
        remaining:'Kalan',
        branch:'Şube',
        shipVia:'Ulaşım Yolu',
        shippedDate:'Sevk Tarihi',
        submit:'Gönder',
        shipNumber:'Tranfer Numarası',
        weight:'Ağırlık',
        boxContent:'Kutu İçerikleri',
        comment:'Açıklama',
        sea:'Deniz',

        air:'Hava',
        pleaseSelectTransportType:'Lütfen Taşıma Türünü Seçiniz',
        pleaseSelectTransportCity:'Lütfen Taşıma Şubesini Seçiniz.',
        transportCount:'Taşıma Sayısı',
        transfer:'Transfer',
        pleaseSelectOne:'Lütfen birini seçin.'

    },
    cn:{

        createUser:'創建用戶',
        newTransfer:'新轉移',
        selectCari:'選擇公司',
        user:'用戶名',
        note:'筆記',
        notes:'筆記',
        add:'添加',
        date:'日期',
        logout:'登出',
        onGoingOrder:'正在進行中的訂單',
        completedOrder:'已完成訂單',
        activeOrder:'活動訂單',
        userName:'用戶名',
        password:'密碼',
        loginDes:'歡迎',
        login:'登錄',
        welcome:'歡迎',
        orderID:'訂單號',
        orderDate:'訂購日期',
        productID:'產品編號',
        barcode:'條碼',
        productName:'產品名稱',
        quantity:'數量',
        remaining:'其餘的',
        branch:'分支',
        shipVia:'交通方式',
        shippedDate:'發貨日期',
        submit:'發送',
        sea:'海',
        air:'空氣',
        pleaseSelectTransportType:'請選擇運送方式',
        pleaseSelectTransportCity:'請選擇交通分行',
        transportCount:'訂單數量',
        transfer:'轉移',
        pleaseSelectOne:'請選擇一個',
        transport:'運輸',
        ordersInWarehouse:'倉庫訂單',
        boxID:'箱号',
        sentBranch:'發送分支',
    }


}
